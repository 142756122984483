/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Import fonts */
@import url("https://use.typekit.net/val2vrk.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;500;600;700&display=swap');

/* Base styles */
body {
  font-family: montserrat, sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* App-specific styles that can't be easily done with Tailwind */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #cf5a16;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Special case styles that are hard to do with Tailwind */
.spline-watermark {
  position: relative !important;
  display: none !important;
}

.face-image {
  position: relative;
  display: block;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.face-image img {
  z-index: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

.face-image img.active {
  z-index: 1;
}

/* Case section styles */
.case {
  display: block;
  margin-bottom: 50px;
  padding: 25px;
  color: #fff;
}

.strategy {
  background-color: #006251;
}

.innovation {
  background-color: #91c46d;
}

.ux {
  background-color: #eeeae0;
  color: #000;
}
.source-code-pro {
  font-family: 'Source Code Pro', monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

/* With specific weights if needed */
.source-code-pro-light {
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
}

.source-code-pro-regular {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

.source-code-pro-bold {
  font-family: 'Source Code Pro', monospace;
  font-weight: 700;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}